<template>
    <div class="vx-row">
        <div class="vx-col w-full sm:w-2/3 mb-base">
            <div class="vs-con-loading__container p-10" id="step6">
                <div class="flex mb-6">
                    <h4 class="mr-3 text-primary">Account Ready</h4>
                    <feather-icon
                        svgClasses="h-6 w-6"
                        :icon="'CheckIcon'"
                        class="text-success mr-3"
                    ></feather-icon>
                </div>
                <p class="mb-6">Your account is now setup and ready to use.</p>

                <p v-if="broker && broker.auto_send" class="mb-6">
                    You will be contacted regarding your primis id, which will
                    allow us to automatically send vouchers on your behalf.
                </p>
                <p class="mb-6">
                    You can now login and start using MyLastWill, it may be
                    benifical to bookmark our login page:
                </p>
                <a class="mb-6 cursor-pointer" @click="$emit('login')"
                    >https://broker.mylastwill.co.uk/login</a
                >
            </div>
        </div>
    </div>
</template>

<script>
import { functions } from '@/mixins/functions'

export default {
    name: 'ConfirmationTab',
    components: {},
    beforeDestroy() {},
    mixins: [functions],
    props: ['signUpFormComplete'],
    computed: {
        broker() {
            return this.$store.getters.broker
        },
    },
    data() {
        return {}
    },
    methods: {},
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{},[_c('div',{style:({
            background: `rgba(var(--vs-primary),.7)`,
        }),attrs:{"slot":"no-body"},slot:"no-body"},[_c('div',{staticClass:"vx-row no-gutter justify-center p-6"},[_c('div',{staticClass:"text-center flex items-center"},[(_vm.signUpForm.logo)?_c('vs-avatar',{attrs:{"src":_vm.getImage,"size":"large"}}):_vm._e(),_c('h3',{staticClass:"text-cente font-bold text-white"},[_vm._v(" "+_vm._s(_vm.signUpForm.company_name || 'Your Company')+" ")])],1)]),_c('div',{staticClass:"vx-row no-gutter w-full"},[_c('div',{staticClass:"vx-col w-full d-theme-dark-bg p-12"},[(!_vm.signUpFormComplete)?_c('div',{staticClass:"vx-row mb-4"},[_c('div',{staticClass:"vx-col w-3/4"},[(_vm.signUpForm.address_line_1)?_c('p',{staticClass:"w-full text-sm"},[_vm._v(" "+_vm._s(_vm.signUpForm.address_line_1)+" ")]):_vm._e(),(_vm.signUpForm.address_line_2)?_c('p',{staticClass:"w-full text-sm"},[_vm._v(" "+_vm._s(_vm.signUpForm.address_line_2)+" ")]):_vm._e(),(_vm.signUpForm.post_town)?_c('p',{staticClass:"w-full text-sm"},[_vm._v(" "+_vm._s(_vm.signUpForm.post_town)+" ")]):_vm._e(),(_vm.signUpForm.post_code)?_c('p',{staticClass:"w-full text-sm"},[_vm._v(" "+_vm._s(_vm.signUpForm.post_code)+" ")]):_vm._e(),(_vm.signUpForm.email)?_c('p',{staticClass:"w-full mt-3 text-sm"},[_vm._v(" "+_vm._s(_vm.signUpForm.email)+" ")]):_vm._e(),(_vm.signUpForm.contact_name)?_c('p',{staticClass:"w-full text-sm"},[_vm._v(" "+_vm._s(_vm.signUpForm.contact_name)+" ")]):_vm._e(),(_vm.signUpForm.contact_number)?_c('p',{staticClass:"w-full text-sm"},[_vm._v(" "+_vm._s(_vm.signUpForm.contact_number)+" ")]):_vm._e()]),_c('div',{staticClass:"vx-col w-1/4"},[_c('div',{staticClass:"flex justify-end"},[(
                                    !_vm.signUpFormComplete &&
                                    _vm.signUpForm.address_line_1
                                )?_c('feather-icon',{staticClass:"cursor-pointer text-primary",attrs:{"svgClasses":"h-3 w-3","icon":'EditIcon'},on:{"click":function($event){return _vm.$emit('gotoTab', 0)}}}):_vm._e()],1)])]):_vm._e(),(
                        !_vm.signUpFormComplete &&
                        (_vm.signUpForm.text_from || _vm.signUpForm.code_prefix)
                    )?_c('div',{staticClass:"vx-row mb-3 text-sm"},[_c('div',{staticClass:"vx-col w-3/4"},[(_vm.signUpForm.text_from)?_c('p',{staticClass:"w-full text-sm"},[_vm._v(" SMS From: "+_vm._s(_vm.signUpForm.text_from)+" ")]):_vm._e(),(_vm.signUpForm.code_prefix)?_c('p',{staticClass:"w-full text-sm"},[_vm._v(" Code Prefix: "+_vm._s(_vm.signUpForm.code_prefix)+" ")]):_vm._e()]),_c('div',{staticClass:"vx-col w-1/4"},[_c('div',{staticClass:"flex justify-end"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"svgClasses":"h-3 w-3","icon":'EditIcon'},on:{"click":function($event){return _vm.$emit('gotoTab', 1)}}})],1)])]):_vm._e(),(!_vm.signUpFormComplete && _vm.plan)?_c('div',{staticClass:"vx-row mb-4 text-sm"},[_c('div',{staticClass:"vx-col w-3/4"},[(_vm.plan)?_c('p',{staticClass:"w-full"},[_vm._v(" Plan: "+_vm._s(_vm.plan.plan)+" £"+_vm._s(_vm.plan.monthly_charge)+"pm ")]):_vm._e()]),_c('div',{staticClass:"vx-col w-1/4"},[_c('div',{staticClass:"flex justify-end"},[(!_vm.signUpFormComplete)?_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"svgClasses":"h-3 w-3","icon":'EditIcon'},on:{"click":function($event){return _vm.$emit('gotoTab', 2)}}}):_vm._e()],1)])]):_vm._e(),(
                        !_vm.signUpFormComplete &&
                        _vm.signUpForm.access_emails &&
                        _vm.signUpForm.access_emails.length
                    )?_c('div',[_c('h6',{staticClass:"mb-3"},[_vm._v("Additional Users")]),_vm._l((_vm.signUpForm.access_emails),function(email,index){return _c('div',{key:index,staticClass:"vx-row access-email text-xs"},[_c('div',{staticClass:"vx-col w-3/4"},[_vm._v(" "+_vm._s(email)+" ")]),_c('div',{staticClass:"vx-col w-1/4 flex flex-row-reverse"},[_c('feather-icon',{staticClass:"cursor-pointer hidden-element text-warning",attrs:{"svgClasses":"h-3 w-3","icon":'TrashIcon'},on:{"click":_vm.removeAccessEmail}})],1)])})],2):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
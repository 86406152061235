<template>
    <div class="vx-row">
        <div class="vx-col w-full sm:w-2/3 mb-base">
            <div
                class="vs-con-loading__container show-overflow items-center pt-4 md:pr-4"
                id="step3"
            >
                <div v-if="plans && !signUpFormComplete" class="vx-row">
                    <div class="vx-col w-full">
                        <div class="mb-6">
                            <h4 class="text-primary mb-2">7. Payment Plan</h4>
                            <small
                                >Select a payment plan that best suits the size
                                of your company:
                            </small>
                        </div>
                    </div>
                    <div class="vx-col pt-4 w-full">
                        <p v-if="errors.plan" class="text-danger">
                            {{ errors.plan }}
                        </p>
                        <vs-table
                            class=""
                            :data="plans"
                            @selected="setPlan"
                            v-model="selected"
                        >
                            <template slot="thead">
                                <vs-th> Name </vs-th>
                                <vs-th> Included Vouchers </vs-th>
                                <vs-th> Additional Will Cost </vs-th>
                                <vs-th> Price Monthly </vs-th>
                            </template>

                            <template slot-scope="{ data }">
                                <vs-tr
                                    :data="tr"
                                    :key="index"
                                    v-for="(tr, index) in data"
                                    :class="{
                                        'is-selected':
                                            selected && selected.id === tr.id,
                                    }"
                                >
                                    <vs-td :data="data[index].plan">
                                        <p>{{ data[index].plan }}</p>
                                        <small>
                                            {{ data[index].description }}</small
                                        >
                                    </vs-td>

                                    <vs-td :data="data[index].included_wills">
                                        {{ data[index].included_wills }}
                                    </vs-td>

                                    <vs-td :data="data[index].price_per_will">
                                        £{{ data[index].price_per_will }}
                                    </vs-td>

                                    <vs-td :data="data[index].monthly_charge">
                                        £{{ data[index].monthly_charge }}
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                    </div>
                </div>
                <div
                    v-else-if="signUpFormComplete && broker && plan"
                    class="p-10"
                >
                    <div class="flex mb-6">
                        <h4 class="mr-3 text-primary">Plan Section Complete</h4>

                        <feather-icon
                            svgClasses="h-6 w-6"
                            :icon="'CheckIcon'"
                            class="text-success mr-3"
                        ></feather-icon>
                    </div>
                    <h6 class="w-full mt-3">{{ plan.plan }}</h6>
                    <small>{{ plan.description }}</small>
                    <p class="w-full mt-3">
                        Base price
                        <span class="font-bold"
                            >£{{ plan.monthly_charge }}</span
                        >
                        per month
                    </p>
                    <p class="w-full">
                        Included invitations of
                        <span class="font-bold">{{ plan.included_wills }}</span>
                        per month
                    </p>
                    <p class="w-full">
                        Additional wills outside of plan charged at
                        <span class="font-bold"
                            >£{{ plan.price_per_will }}</span
                        >
                        per will
                    </p>
                </div>
            </div>
        </div>
        <div
            v-if="!signUpFormComplete"
            class="vx-col hidden sm:block w-full sm:w-1/3"
        >
            <OverviewCard
                :signUpFormComplete="signUpFormComplete"
                @gotoTab="$emit('gotoTab', $event)"
            />
        </div>
    </div>
</template>

<script>
import { http } from '@/services'
import OverviewCard from '../tabs/Overview.vue'

export default {
    name: 'PlanTab',
    props: ['signUpFormComplete'],
    components: {
        OverviewCard,
    },
    created() {
        this.plansFetch()
    },
    mounted() {
        this.errors = Object.assign({}, this.$store.getters.signUpFormErrors)
        if (this.$store.getters.selectedPlanDetails) {
            this.selected = Object.assign(
                {},
                this.$store.getters.selectedPlanDetails
            )
        }
    },
    watch: {
        '$store.state.signUpFormErrors': {
            immediate: true,
            deep: true,
            handler(data) {
                this.errors = this.$store.getters.signUpFormErrors
            },
        },
    },
    computed: {
        broker() {
            return this.$store.getters.broker
        },
        plan() {
            return this.$store.getters.selectedPlanDetails
        },
    },
    data() {
        return {
            plans: [],
            errors: null,
            selected: null,
        }
    },
    methods: {
        plansFetch() {
            http.get('broker_plans_list')
                .then((response) => {
                    this.plans = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        setPlan(plan) {
            this.$store.commit('signUpForm', { plan: plan.id })
            this.$store.commit('selectedPlanDetails', plan)
        },
    },
}
</script>

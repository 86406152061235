<template>
    <div class="vx-row">
        <div class="vx-col w-full sm:w-2/3 mb-base">
            <div
                v-if="!signUpFormComplete"
                class="vs-con-loading__container show-overflow items-center pt-4 md:pr-4"
                id="step2"
            >
                <form>
                    <div class="vx-row pt-0 p-4">
                        <div class="vx-col w-full">
                            <div class="mb-6">
                                <h4 class="text-primary mb-2">4. Vouchers</h4>
                                <small>
                                    Invitations to create a will are sent to
                                    clients that contain a code, this code is
                                    personalised by prepending a chosen sequence
                                    of charactors to a unique voucher code, an
                                    example code would look like
                                    <span class="font-bold"
                                        >{{ code_prefix || 'DIY' }}-T7QW</span
                                    >, where
                                    <span class="font-bold">{{
                                        code_prefix || 'DIY'
                                    }}</span>
                                    is an abbriviation of your company's name.
                                </small>
                            </div>
                        </div>
                        <div class="vx-col pt-4 w-full mb-6">
                            <div class="vx-row">
                                <div class="vx-col w-full sm:w-1/2 mb-6">
                                    <vs-input
                                        icon="icon-code"
                                        icon-pack="feather"
                                        class="w-full"
                                        v-model="code_prefix"
                                        label="Code Prefix"
                                        description-text="This should be a short code that will start of voucher code thats are sent to your clients"
                                        :danger="errors.code_prefix !== null"
                                        :danger-text="errors.code_prefix"
                                    ></vs-input>
                                </div>
                            </div>
                        </div>

                        <div class="vx-col w-full">
                            <div class="mb-6">
                                <h4 class="text-primary mb-2">
                                    5. Text Messages
                                </h4>
                                <small>
                                    Where invitations are sent to your client
                                    using sms, you can personalise the sender's
                                    display name:
                                </small>
                            </div>
                        </div>
                        <div class="vx-col pt-4 w-full mb-6">
                            <div class="vx-row">
                                <div class="vx-col w-full sm:w-1/2 mb-6">
                                    <vs-input
                                        icon="icon-send"
                                        icon-pack="feather"
                                        class="w-full"
                                        v-model="text_from"
                                        label="SMS From"
                                        description-text="This will be the sender's display name"
                                        :danger="errors.text_from !== null"
                                        :danger-text="errors.text_from"
                                    ></vs-input>
                                </div>
                            </div>
                        </div>

                        <div class="vx-col w-full">
                            <div class="mb-6">
                                <h4 class="text-primary mb-2">
                                    6. Primis Network
                                </h4>
                                <small
                                    >If you are part of Primis then you can
                                    enable the auto sending of invtations to
                                    your client every month, we will contact you
                                    regarding your Primis Network ID.</small
                                >
                            </div>
                        </div>
                        <div class="vx-col pt-4 w-full">
                            <div class="vx-row mb-8">
                                <div
                                    class="vx-col w-full mb-6 flex justify-between"
                                >
                                    <label class="vs-input--label"
                                        >{{
                                            auto_send ? 'Enable' : 'Disable'
                                        }}
                                        Auto Send Invitations</label
                                    >
                                    <vs-checkbox
                                        v-model="auto_send"
                                    ></vs-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div v-else-if="signUpFormComplete && broker" class="p-10">
                <div class="flex mb-6">
                    <h4 class="mr-3 text-primary">Vouchers Section Complete</h4>

                    <feather-icon
                        svgClasses="h-6 w-6"
                        :icon="'CheckIcon'"
                        class="text-success mr-3"
                    ></feather-icon>
                </div>
                <p class="w-full mb-3">
                    SMS messages will appear to be sent from
                    <span class="font-bold">{{ broker.text_from }}</span>
                </p>
                <p class="w-full">
                    The vouchers sent to clients will be personalised by having
                    the code
                    <span class="font-bold">{{ broker.code_prefix }}</span>
                    prepended to thier code, an example code would look like
                    <span class="font-bold">{{ broker.code_prefix }}-T7QW</span
                    >.
                </p>
            </div>
        </div>
        <div
            v-if="!signUpFormComplete"
            class="vx-col hidden sm:block w-full sm:w-1/3"
        >
            <OverviewCard
                :signUpFormComplete="signUpFormComplete"
                @gotoTab="$emit('gotoTab', $event)"
            />
        </div>
    </div>
</template>

<script>
import OverviewCard from '../tabs/Overview.vue'

export default {
    name: 'InvitationsTab',
    components: { OverviewCard },
    props: ['signUpFormComplete'],

    mounted() {
        this.errors = Object.assign({}, this.$store.getters.signUpFormErrors)
    },
    watch: {
        '$store.state.signUpFormErrors': {
            immediate: true,
            deep: true,
            handler(data) {
                this.errors = this.$store.getters.signUpFormErrors
            },
        },
    },
    computed: {
        broker() {
            return this.$store.getters.broker
        },
        code_prefix: {
            set(value) {
                this.$store.commit('signUpForm', {
                    code_prefix: value,
                })
            },
            get() {
                return this.$store.getters.signUpForm.code_prefix
            },
        },
        text_from: {
            set(value) {
                this.$store.commit('signUpForm', {
                    text_from: value,
                })
            },
            get() {
                return this.$store.getters.signUpForm.text_from
            },
        },
        auto_send: {
            set(value) {
                this.$store.commit('signUpForm', {
                    auto_send: value,
                })
            },
            get() {
                return this.$store.getters.signUpForm.auto_send
            },
        },
    },
    data() {
        return {
            errors: null,
        }
    },
}
</script>

<template>
    <div class="vx-row">
        <div class="vx-col w-full sm:w-2/3 mb-base">
            <div
                class="vs-con-loading__container show-overflow items-center pt-4 md:pr-4"
                id="step4"
            >
                <div v-if="!signUpFormComplete" class="vx-row">
                    <div class="vx-col w-full mb-6">
                        <div class="">
                            <h4 class="text-primary mb-2">
                                8. Username and Password
                            </h4>
                            <small
                                >This will be your username and password to
                                access your account tools:</small
                            >
                        </div>
                    </div>
                    <div class="vx-col pt-4 w-full mb-6">
                        <div class="vx-row">
                            <div class="vx-col w-full sm:w-2/3 mb-6">
                                <vs-input
                                    class="w-full"
                                    v-model="username"
                                    label="Username"
                                    placeholder="Your email address"
                                    :danger="errors.username !== null"
                                    :danger-text="errors.username"
                                />
                            </div>
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full sm:w-2/3 mb-6">
                                <vs-input
                                    class="w-full"
                                    v-model="password1"
                                    label="Password"
                                    placeholder="Password"
                                    type="password"
                                    :danger="errors.password1 !== null"
                                    :danger-text="errors.password1"
                                />
                            </div>

                            <div class="vx-col w-full sm:w-2/3 mb-10">
                                <vs-input
                                    class="w-full"
                                    v-model="password2"
                                    label="Repeat Password"
                                    placeholder="Repeat Password"
                                    type="password"
                                    :danger="errors.password2 !== null"
                                    :danger-text="errors.password2"
                                />
                            </div>

                            <div class="vx-col w-full sm:w-2/3 mb-6">
                                <div class="flex justify-between">
                                    <label class="vs-input--label"
                                        >I agree to the
                                        <a
                                            @click="show.termsModal = true"
                                            target="_blank"
                                            >Introducer Terms and Conditions</a
                                        ></label
                                    >
                                    <vs-checkbox
                                        v-model="terms_and_conditions"
                                    ></vs-checkbox>
                                </div>
                                <p
                                    v-if="errors.terms_and_conditions"
                                    class="text-sm text-danger"
                                >
                                    {{ errors.terms_and_conditions }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="vx-col w-full">
                        <div class="mb-6">
                            <h4 class="text-primary mb-2">
                                9. Additional Users
                            </h4>
                            <small
                                >If required you can add multiple additional
                                team members to your account, they will have
                                access to the same tools as yourself, just enter
                                thier email address's below and they will
                                recieve an email on how to gain access:</small
                            >
                        </div>
                    </div>
                    <div class="vx-col pt-6 w-full sm:w-2/3">
                        <form v-on:submit.prevent>
                            <vx-input-group>
                                <vs-input
                                    placeholder="Email address"
                                    v-model="accessEmail"
                                    color="primary"
                                    @keydown.enter="add"
                                />

                                <template slot="append">
                                    <div class="append-text btn-addon">
                                        <vs-button
                                            @click="add"
                                            color="primary"
                                            :disabled="!accessEmail"
                                            >Add User</vs-button
                                        >
                                    </div>
                                </template>
                            </vx-input-group>
                            <div
                                class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
                                style="height: 19px"
                            >
                                <span class="span-text-validation">
                                    {{ accessEmailError }}
                                </span>
                            </div>
                        </form>
                    </div>
                </div>

                <div v-else-if="signUpFormComplete && broker" class="p-10">
                    <div class="flex mb-6">
                        <h4 class="mr-3 text-primary">
                            Users Section Complete
                        </h4>

                        <feather-icon
                            svgClasses="h-6 w-6"
                            :icon="'CheckIcon'"
                            class="text-success mr-3"
                        ></feather-icon>
                    </div>
                    <p class="w-full mb-3">
                        Your username is
                        <span class="font-bold">{{ broker.email }}</span>
                    </p>

                    <p
                        v-if="
                            broker.access_emails && broker.access_emails.length
                        "
                        class="w-full mb-3"
                    >
                        You have sent invitations to the following email
                        addresses, these people will be able to register for
                        access to your account (you can revoke access from
                        within your account):
                    </p>
                    <ul
                        v-if="
                            broker.access_emails && broker.access_emails.length
                        "
                    >
                        <li
                            v-for="(email, index) in broker.access_emails"
                            :key="index"
                            class="font-bold"
                        >
                            {{ email }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div
            v-if="!signUpFormComplete"
            class="vx-col hidden sm:block w-full sm:w-1/3"
        >
            <OverviewCard
                :signUpFormComplete="signUpFormComplete"
                @gotoTab="$emit('gotoTab', $event)"
            />
        </div>
        <terms-modal
            :show-data="show.termsModal"
            @close="show.termsModal = false"
        />
    </div>
</template>

<script>
import VxInputGroup from '../../../components/vx-input-group/VxInputGroup.vue'
import OverviewCard from '../tabs/Overview.vue'
import TermsModal from './TermsModal.vue'

import { functions } from '@/mixins/functions'

export default {
    components: { VxInputGroup, OverviewCard, TermsModal },
    name: 'AccessTab',
    props: ['signUpFormComplete'],

    mixins: [functions],
    mounted() {
        this.errors = Object.assign({}, this.$store.getters.signUpFormErrors)
    },
    watch: {
        '$store.state.signUpFormErrors': {
            immediate: true,
            deep: true,
            handler(data) {
                this.errors = this.$store.getters.signUpFormErrors
            },
        },
    },
    computed: {
        broker() {
            return this.$store.getters.broker
        },
        username: {
            set(value) {
                this.$store.commit('signUpForm', {
                    username: value,
                })
            },
            get() {
                return this.$store.getters.signUpForm.username
            },
        },
        password1: {
            set(value) {
                this.$store.commit('signUpForm', {
                    password1: value,
                })
            },
            get() {
                return this.$store.getters.signUpForm.password1
            },
        },
        password2: {
            set(value) {
                this.$store.commit('signUpForm', {
                    password2: value,
                })
            },
            get() {
                return this.$store.getters.signUpForm.password2
            },
        },
        terms_and_conditions: {
            set(value) {
                this.$store.commit('signUpForm', {
                    terms_and_conditions: value,
                })
            },
            get() {
                return this.$store.getters.signUpForm.terms_and_conditions
            },
        },
    },
    data() {
        return {
            errors: null,
            accessEmail: null,
            accessEmailError: null,
            show: {
                termsModal: false,
            },
        }
    },
    methods: {
        add() {
            if (this.validEmail(this.accessEmail)) {
                this.$store.commit('addAccessEmail', this.accessEmail)
                this.accessEmail = null
                this.accessEmailError = null
                this.$vs.notify({
                    text: 'User Added',
                    color: 'success',
                    position: 'top-center',
                })
            } else {
                this.accessEmailError = 'Invalid email address'
            }
        },
        remove(index) {
            this.form.access_emails.splice(index, 1)
        },
    },
}
</script>

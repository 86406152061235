<template>
    <vs-popup
        title="Introducer Terms and Conditions"
        :active.sync="show"
        class="vs-con-loading__container"
        id="loading-container_referral_modal"
    >
        <div id="body" class="main-container terms-container">
            <div class="section terms-sec" id="1">
                <h3 class="mb-6 text-primary">1. Definitions</h3>
                <p>
                    These definitions apply unless the context requires a
                    different interpretation:
                </p>
                <ul>
                    <li>
                        <span>"Confidential Information"</span> means all
                        information about the parties to this agreement,
                        including any information which may give a commercially
                        competitive advantage to any other person. It does not
                        include information that it is reasonably necessary to
                        disclose to a customer or other person in the usual
                        course of business so far as that information is
                        disclosed in those circumstances. It includes among
                        other things: data or information relating to product
                        plans, marketing strategies, business plans, and
                        internal performance results of a party, and the
                        customers and suppliers of any of them; It includes
                        information about the Intellectual Property and the
                        Know-how.
                    </li>

                    <li>
                        <span>"Intellectual Property"</span> means intellectual
                        property owned by the Company, of every sort, whether or
                        not registered or registrable in any country, including
                        intellectual property of kinds coming into existence
                        after today; and including, among others, patents,
                        trademarks, unregistered marks, designs, copyrights,
                        software, domain names, discoveries, Know-how, creations
                        and inventions, together with all rights which are
                        derived from those rights.
                    </li>
                    <li>
                        <span>"Know-how"</span> means methods, procedures and
                        ways of working and organising which are not capable of
                        protection as copyright.
                    </li>
                    <li>
                        <span>"Net Sales Receipts"</span> means the money
                        receivable for sales of the Products after deduction of
                        any sales tax and service provider costs charged by any
                        person relating to the payment or transfer of money
                        until it reaches the bank account of the Company.
                    </li>
                    <li>
                        <span>"Product"</span> means any product, goods or
                        service offered for sale from time to time by the
                        Company to the Introducer.
                    </li>
                    <li><span>"Territory"</span>means the United Kingdom</li>
                </ul>
            </div>
            <div class="section terms-sec" id="2">
                <h3 class="mb-6 mt-6 text-primary">2. Interpretation</h3>

                <p>In this agreement unless the context otherwise requires:</p>
                <p>
                    A reference to one gender shall include any or all genders
                    and a reference to the singular may be interpreted where
                    appropriate as a reference to the plural and vice versa.
                </p>
                <p>
                    A reference to a person includes a human individual, a
                    corporate entity and any organisation which is managed or
                    controlled as a unit.
                </p>
                <p>
                    A reference to a person includes reference to that person’s
                    successors, legal representatives, permitted assigns and any
                    person to whom rights and obligations are transferred or
                    pass as a result of a merger, division, reconstruction or
                    other re-organisation involving that person.
                </p>
                <p>
                    In the context of permission, “may not” in connection with
                    an action of yours, means “must not”.
                </p>
                <p>
                    The headings to the paragraphs and schedules (if any) to
                    this agreement are inserted for convenience only and do not
                    affect the interpretation.
                </p>
                <p>
                    Any agreement by any party not to do or omit to do something
                    includes an obligation not to allow some other person to do
                    or omit to do that same thing.
                </p>
                <p>
                    A reference to the knowledge, information, belief or
                    awareness of any person shall be deemed to include the
                    knowledge, information, belief or awareness that person
                    would have if he had made reasonable inquiries.
                </p>
                <p>
                    The words “without limitation” shall be deemed to follow any
                    use of the words “include” or “including” herein.
                </p>
                <p>
                    A reference to an act or regulation includes new law of
                    substantially the same intent as the act or regulation
                    referred to.
                </p>
                <p>
                    In any indemnity, a reference to costs or expenses shall be
                    construed as including the estimated cost of management time
                    of the indemnified party.
                </p>
                <p>
                    All money sums mentioned in this agreement are calculated
                    net of VAT, which will be charged when payment is due.
                </p>
                <p>
                    This agreement is made only in the English language. If
                    there is any conflict in meaning between the English
                    language version of this agreement and any version or
                    translation of this agreement in any other language, the
                    English language version shall prevail.
                </p>
            </div>
            <div class="section terms-sec" id="3">
                <h3 class="mb-6 mt-6 text-primary">3. Basis of agreement</h3>
                <p>
                    Nothing in this agreement shall be deemed to create a
                    partnership or the relationship of employer and employee
                    between the parties. Neither party is an agent of the other
                    nor has any authority to bind the other.
                </p>
                <p>
                    This agreement contains the entire agreement between the
                    parties and supersedes all previous agreements and
                    understandings between the parties. Each party acknowledges
                    that, in entering into this agreement, he does not rely on
                    any representation, warranty or other term not forming part
                    of this agreement.
                </p>
                <p>
                    All conditions, warranties and other terms implied by the
                    law of any country are hereby excluded as far as the law of
                    such country permits.
                </p>
                <p>
                    The Company may assign or transfer the benefit of this
                    agreement.
                </p>
                <p>
                    The Introducer has agreed to seek out clients/customers for
                    the Company for the sale of the Products within the United
                    Kingdom on the terms of this agreement.
                </p>
                <p>
                    This agreement is personal to the Introducer. The Introducer
                    may not delegate or sub-contract any of its obligations
                    under this agreement.
                </p>
            </div>
            <div class="section terms-sec" id="4">
                <h3 class="mb-6 mt-6 text-primary">4. Terms of appointment</h3>

                <p>
                    The Company agrees to pay the Introducer for each
                    introduction to a client/customer in the terms set out in
                    Schedule 1, subject to the terms of this agreement.
                </p>
                <p>
                    The Introducer is not bound to seek out clients/customers
                    nor is the Company bound to supply any client/customer with
                    Products.
                </p>
                <p>
                    This agreement does not restrict the Company from appointing
                    any other person to any marketing function within the same
                    territory.
                </p>
                <p>
                    The Company now authorises the Introducer to act as his
                    Introducer specifically to:
                </p>
                <ul>
                    <li>
                        identify prospective clients for the Company’s Products;
                    </li>
                    <li>
                        negotiate sales contracts within the parameters set by
                        the Company and notified to the Introducer from time to
                        time.
                    </li>
                    <li>
                        monitor contract performance by the Company and the
                        customer, including acceptance of contract terms,
                        delivery of services, payment, and other matters covered
                        in the Company’s contract terms with the customer.
                    </li>
                </ul>
                <p>
                    The Introducer shall not be responsible for obtaining
                    regulatory approvals and licences necessary for the sale of
                    Products in the Territory.
                </p>
                <p>
                    The Introducer may display and use the trademark and/ or
                    logo of the Company subject to the prior written permission
                    of the Company. The Company may refuse permission without
                    giving a reason.
                </p>
                <p>
                    The Introducer will pay all expenses incurred by him in
                    searching for clients/customers for the Products.
                </p>
            </div>

            <div class="section terms-sec" id="5">
                <h3 class="mb-6 mt-6 text-primary">
                    5. Introducer’s way of working
                </h3>
                <p>The Introducer agrees that he will:</p>
                <ul>
                    <li>
                        look after the interests of the Company and always act
                        in good faith;
                    </li>
                    <li>
                        keep the Company informed as often as reasonably
                        possible about any development or happening which may be
                        relevant to this agreement;
                    </li>
                    <li>
                        comply with the reasonable instructions of the Company;
                    </li>
                    <li>
                        cultivate and maintain good relations with clients and
                        potential clients in the Territory;
                    </li>
                    <li>
                        comply with all applicable laws and regulations relating
                        to the sale of the Products;
                    </li>
                    <li>
                        not use any name or mark or get-up for any other product
                        which could be confused with the name, mark or get-up of
                        any of the Products.
                    </li>
                    <li>
                        bring to the notice of anyone he might introduce the
                        Company’s terms and conditions of sale /service
                        provision so as to avoid misunderstandings when a
                        transaction proceeds.
                    </li>
                    <li>
                        not make or give any promise, warranty, guarantee or
                        representation concerning the Products other than those
                        contained in the terms and conditions of sale.
                    </li>
                    <li>
                        immediately inform the Company of any complaint he
                        receives concerning the Products.
                    </li>
                    <li>
                        not use any advertising or marketing material except
                        that supplied by the Company.
                    </li>
                </ul>
            </div>

            <div class="section terms-sec" id="6">
                <h3 class="mb-6 mt-6 text-primary">
                    6. Fee structure (will drafting)
                </h3>
                <p>
                    The Company will make the below charges for providing will
                    writing services for clients introduced by the Introducer:
                </p>
                <ul>
                    <li>
                        £199 payable monthly to include 30 will documents, for
                        each will drafted thereafter a fee of £10 will be
                        payable (option ‘a’); or
                    </li>
                    <li>
                        £499 payable monthly to include 100 will documents, for
                        each will drafted thereafter a fee of £8 will be payable
                        (option ‘b’).
                    </li>
                </ul>
            </div>

            <div class="section terms-sec" id="7">
                <h3 class="mb-6 mt-6 text-primary">
                    7. Payment procedure (commissions)
                </h3>
                <p>
                    The Company will pay 25% commission to the Introducer on Net
                    Sales Receipts for ‘Legal Services, Document Storage &
                    Estate Administration’.
                </p>
                <p>
                    The Company alone will be responsible for collection of
                    money from clients.
                </p>
                <p>
                    Before the 10th of each month the Company will send to the
                    Introducer a statement listing the invoice numbers for sales
                    in the previous month and the amount payable on each. At the
                    same time, and not later, the Company shall make payment to
                    the Introducer by electronic transfer.
                </p>
                <p>
                    All sums payable under this agreement are exclusive of any
                    value added tax or other tax applicable to the payment,
                    which shall be added to or deducted from the sum concerned,
                    as the case may be.
                </p>
            </div>

            <div class="section terms-sec" id="8">
                <h3 class="mb-6 mt-6 text-primary">
                    8. Rights and duties of the Company
                </h3>

                <p>
                    The Company remains at all times and in all circumstances in
                    control of Products prices and terms of trade, all of which
                    he may change at any time. Subject to that, the Company
                    will:
                </p>
                <ol>
                    <li>
                        look after the interests of the Introducer and always
                        act in good faith;
                    </li>
                    <li>supply to the Introducer from time to time:</li>
                    <li>
                        <ul>
                            <li>
                                appropriate quantities of promotional material
                                and other appropriate documentation;
                            </li>
                            <li>
                                names of any clients to whom the Company has
                                sold Products not subject to payment.
                            </li>
                        </ul>
                    </li>
                    <li>
                        honour all contracts for sale of the Products concluded
                        by the Introducer and if there is any good reason why
                        the Company is unable to honour a contract, then he will
                        inform the Introducer of his refusal of it./p>
                    </li>
                    <li>
                        give reasonable notice to the Introducer of any proposed
                        change in the prices of Products or in other terms of
                        trade.
                    </li>
                    <li>
                        promptly and efficiently deal with any complaint or
                        enquiry made by a customer;
                    </li>
                    <li>
                        comply with all the applicable laws and regulations
                        relating to the nature, method of packaging and
                        labelling of the Products for sale in the Territory. But
                        the Company shall not be in breach of this agreement for
                        any failure to comply with a law or regulation unknown
                        to him;
                    </li>
                    <li>
                        provide to the Introducer each month a list of clients
                        located within the Territory but on whose purchases no
                        payment is payable to the Introducer.
                    </li>
                </ol>
            </div>
            <div class="section terms-sec" id="9">
                <h3 class="mb-6 mt-6 text-primary">
                    9. Confidential Information
                </h3>

                <p>
                    In respect of the Confidential Information of the other,
                    each of the parties now agrees that he will:
                </p>
                <ul>
                    <li>
                        use his best endeavours to keep confidential any
                        Confidential Information which he or they may acquire;
                    </li>
                    <li>
                        not store, copy, or use the Confidential Information in
                        any place or in any electronic form which may be
                        accessible to any other person.
                    </li>
                    <li>
                        not use or disclose Confidential Information except to
                        such extent as is necessary for the purposes of this
                        agreement, or with the consent of the other of them;
                    </li>
                    <li>
                        explain to all relevant employees, agents and
                        sub-contractors about the provisions of this paragraph
                        and will take appropriate steps to ensure compliance
                        with these provisions by their employees, agents and
                        sub-contractors.
                    </li>
                    <li>
                        from today until the expiry of five years from the
                        termination of this agreement, keep the Confidential
                        Information secret and not divulge or make it known to
                        anyone nor use it for the benefit of himself or any
                        other person.
                    </li>
                </ul>
                <p>This paragraph does not apply to disclosure:</p>
                <ul>
                    <li>made by order of the court;</li>
                    <li>
                        of information or knowledge which comes into the public
                        domain otherwise than by reason of the default of a
                        party;
                    </li>
                    <li>
                        as may be minimally necessary to give effect to the
                        purposes of this agreement whilst ever the agreement is
                        operational.
                    </li>
                </ul>
                <p>
                    The obligations set out in this paragraph shall continue to
                    be fully effective indefinitely even if a party has
                    destroyed or returned the Confidential Information.
                </p>
            </div>
            <div class="section terms-sec" id="10">
                <h3 class="mb-6 mt-6 text-primary">
                    10. Intellectual Property
                </h3>

                <p>
                    The Introducer acknowledges that the Intellectual Property
                    belongs exclusively to the Company and if the Introducer
                    should create any new intellectual property relating to the
                    Products, then that too shall belong to the Company. The
                    Introducer agrees that he will:
                </p>
                <ol>
                    <li>
                        take any action necessary to confirm or vest the title
                        of the Company to any new intellectual property.
                    </li>
                    <li>
                        be vigilant in seeking out any infringement of the
                        Intellectual Property rights of the Company and to bring
                        to the attention of the Company any that he finds or
                        suspects.
                    </li>
                    <li>
                        notify the Company of any suspected infringement of his
                        Intellectual Property rights;
                    </li>
                    <li>
                        take such reasonable action as the Company shall direct
                        at the Company's expense in relation to any infringement
                        which may be found or suspected;
                    </li>
                    <li>
                        not use any name or mark similar to or capable of being
                        confused with any name or mark belonging to the Company.
                    </li>
                </ol>
            </div>
            <div class="section terms-sec" id="11">
                <h3 class="mb-6 mt-6 text-primary">11. No competition</h3>

                <p>
                    The Company agrees that he will not, neither for himself nor
                    for any other person, directly or indirectly:
                </p>
                <ul>
                    <li>
                        advise or promote the sale of any mortgage or protection
                        products or services to clients who have been introduced
                        by the Introducer (unless expressly agreed by the
                        introducer).
                    </li>
                    <li>
                        advise, instruct, do or assist in the sale of any
                        mortgage or protection products or services to clients
                        who have been introduced by the Introducer (unless
                        expressly agreed by the introducer).
                    </li>
                </ul>
                <p>
                    The Company agrees that the provisions of this paragraph are
                    fair and reasonably required for the protection of the
                    Introducer’’s business.
                </p>
            </div>
            <div class="section terms-sec" id="12">
                <h3 class="mb-6 mt-6 text-primary">12. Termination</h3>
                <p>Either party may terminate this agreement:</p>
                <p>by giving 7 days notice in writing to the other;</p>
                <ol>
                    <li>
                        immediately if control of the Introducer, being a
                        company, is sold or transferred, other than in the
                        course of a reconstruction not involving a substantial
                        change of management;
                    </li>
                    <li>
                        immediately if the other of them fails to pay any money
                        due within 28 days of it becoming due.
                    </li>
                    <li>
                        immediately, if the other of them has committed a
                        serious breach of this agreement;
                    </li>
                    <li>
                        immediately, if a receiver or administrator (by any name
                        or title) is appointed to the assets of the other).
                    </li>
                </ol>
                <p>
                    Any termination of this agreement by this paragraph shall be
                    without prejudice to any other rights or remedies to which a
                    party may be entitled.
                </p>
            </div>
            <div class="section terms-sec" id="13">
                <h3 class="mb-6 mt-6 text-primary">
                    13. Consequences of termination
                </h3>
                <p>Upon the termination of this agreement for any reason:</p>
                <p>
                    the Introducer shall cease to advertise, promote or sell the
                    Products;
                </p>
                <ul>
                    <li>
                        ement shall continue to be effective until all Products
                        contracted for sale before termination have been
                        delivered and all consequential financial procedures
                        have been completed including the payment of all money
                        due to the Introducer.
                    </li>
                    <li>
                        the parties continue to have the same legal rights and
                        remedies against each other as they had before
                        termination.
                    </li>
                </ul>
            </div>
            <div class="section terms-sec" id="14">
                <h3 class="mb-6 mt-6 text-primary">
                    14. Data Protection Act 2018 Compliance
                </h3>

                <p>
                    To satisfy the Company’s and the Introducer’s legal
                    obligations in connection with the personal data of a
                    customer or client located in the European Union, each of
                    the Company and the Introducer agrees that he will comply
                    with the principles set out in the Data Protection Act 2018.
                    These are fully described in the Schedule 1, which forms
                    part of this agreement.
                </p>
                <p>
                    Those obligations shall continue to apply after expiry or
                    termination of this agreement for any reason.
                </p>
            </div>
            <div class="section terms-sec" id="15">
                <h3 class="mb-6 mt-6 text-primary">
                    15. Miscellaneous matters
                </h3>
                <p>
                    The Introducer undertakes to provide to the Company his
                    current land address, e-mail address, telephone number as
                    often as they are changed together with all information that
                    the Company may require to enable him to fulfil his
                    obligations under this contract.
                </p>
                <p>
                    No amendment or variation to this agreement is valid unless
                    in writing, signed by each of the parties or his authorised
                    representative.
                </p>
                <p>
                    The parties acknowledge and agree that this agreement has
                    been jointly drawn by them and accordingly it should not be
                    construed strictly against either party.
                </p>
                <p>
                    If any term or provision of this agreement is at any time
                    held by any jurisdiction to be void, invalid or
                    unenforceable, then it shall be treated as changed or
                    reduced, only to the extent minimally necessary to bring it
                    within the laws of that jurisdiction and to prevent it from
                    being void and it shall be binding in that changed or
                    reduced form. Subject to that, each provision shall be
                    interpreted as severable and shall not in any way affect any
                    other of these terms.
                </p>
                <p>
                    The rights and obligations of the parties set out in this
                    agreement shall pass to any permitted successor in title.
                </p>
                <p>
                    Any obligation in this agreement intended to continue to
                    have effect after termination or completion shall so
                    continue.
                </p>
                <p>
                    No failure or delay by any party to exercise any right,
                    power or remedy will operate as a waiver of it nor indicate
                    any intention to reduce that or any other right in the
                    future.
                </p>
                <p>
                    Any communication to be served on either of the parties by
                    the other shall be delivered by hand or sent by first class
                    post or recorded delivery or by e-mail.
                </p>
                <p>It shall be deemed to have been delivered:</p>
                <ul>
                    <li>if delivered by hand: on the day of delivery;</li>
                    <li>
                        if sent by post to the correct address: within 72 hours
                        of posting;
                    </li>
                    <li>
                        If sent by e-mail to the address from which the
                        receiving party has last sent e-mail: within 24 hours if
                        no notice of non-receipt has been received by the
                        sender.
                    </li>
                </ul>
                <p>
                    In the event of a dispute between the parties to this
                    agreement, then they undertake to attempt to settle the
                    dispute by engaging in good faith with the other in a
                    process of mediation before commencing arbitration or
                    litigation.
                </p>
                <p>
                    This agreement does not give any right to any third party
                    under the Contracts (Rights of Third Parties) Act 1999 /
                    Contracts (Rights of Third Parties) (Scotland) Act 2017 or
                    otherwise, except that any provision in this agreement which
                    excludes or restricts the liability of our directors,
                    officers, employees, subcontractors, Introducers and
                    affiliated companies, may be enforced under that act.
                </p>
                <p>
                    This agreement may be executed in multiple counterparts each
                    of which shall be considered an original and all or anyone
                    of which constitute one and the same instrument.
                </p>
                <p>
                    Neither party shall be liable for any failure or delay in
                    performance of this agreement which is caused by
                    circumstances beyond its reasonable control.
                </p>
                <p>
                    In the event of any conflict between any term of this
                    agreement and the provisions of the articles of a limited
                    Company or any comparable document intended to regulate any
                    other corporate or collective body, then the terms of this
                    agreement shall prevail.
                </p>
                <p>
                    The validity, construction and performance of this agreement
                    shall be governed by the laws of England and Wales and the
                    parties agree that any dispute arising from it shall be
                    litigated only in that country.
                </p>
            </div>
        </div>
    </vs-popup>
</template>

<script>
export default {
    name: 'TermsModal',
    props: ['showData'],
    computed: {
        show: {
            get() {
                return this.showData
            },
            set(value) {
                this.$emit('close')
            },
        },
    },
}
</script>

<style scoped>
.section p {
    padding: 10px;
}
span {
    font-weight: 700;
}
li {
    padding: 10px;
}
ul {
    list-style-type: circle;
    padding-left: 30px;
}

ol {
    list-style-type: lower-alpha;
    padding-left: 30px;
}
</style>

<template>
    <div class="vx-row">
        <div class="vx-col w-full sm:w-2/3 mb-base">
            <div
                id="step1"
                v-if="!signUpFormComplete"
                class="vs-con-loading__container show-overflow items-center pt-4 md:pr-4"
            >
                <form>
                    <div class="vx-row pt-0 p-4">
                        <div class="vx-col w-full">
                            <div class="mb-6">
                                <h4 class="text-primary mb-2">
                                    1. Company Details
                                </h4>
                                <small
                                    >Welcome, start by entering basic company
                                    details:</small
                                >
                            </div>
                        </div>
                        <div class="vx-col pt-4 w-full">
                            <div class="vx-row mb-8">
                                <div class="vx-col w-full">
                                    <vs-input
                                        icon="icon-home"
                                        icon-pack="feather"
                                        class="w-full"
                                        v-model="company_name"
                                        label="Company Name"
                                        description-text="This is the name that will appear in any branded messages,
                                         there is no need to include 'Ltd' or 'Limited'."
                                        :danger="errors.company_name !== null"
                                        :danger-text="errors.company_name"
                                    ></vs-input>
                                </div>
                            </div>

                            <div class="vx-row">
                                <div class="vx-col w-full">
                                    <AddressSearch
                                        class="mb-3"
                                        @addressSelected="setAddress"
                                    >
                                    </AddressSearch>

                                    <div class="flex justify-end">
                                        <span
                                            @click="
                                                show.editAddress = !show.editAddress
                                            "
                                            class="text-right vs-input--label cursor-pointer"
                                        >
                                            {{
                                                show.editAddress
                                                    ? 'Hide Address'
                                                    : 'Edit Address'
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-6 mt-3" v-show="show.editAddress">
                                <div class="vx-row">
                                    <div class="vx-col w-full sm:w-2/3">
                                        <vs-input
                                            class="w-full mb-6"
                                            v-model="address_line_1"
                                            label-placeholder="Address 1"
                                            :danger="
                                                errors.address_line_1 !== null
                                            "
                                            :danger-text="errors.address_line_1"
                                        ></vs-input>
                                    </div>
                                    <div class="vx-col w-full sm:w-2/3">
                                        <vs-input
                                            class="w-full mb-6"
                                            v-model="address_line_2"
                                            label-placeholder="Address 2"
                                            :danger="
                                                errors.address_line_2 !== null
                                            "
                                            :danger-text="errors.address_line_2"
                                        ></vs-input>
                                    </div>
                                </div>

                                <div class="vx-row">
                                    <div class="vx-col w-1/2 sm:w-1/3">
                                        <vs-input
                                            class="w-full mb-6"
                                            v-model="post_town"
                                            label-placeholder="Post Town"
                                            :danger="errors.post_town !== null"
                                            :danger-text="errors.post_town"
                                        ></vs-input>
                                    </div>
                                    <div class="vx-col w-1/2 sm:w-1/3">
                                        <vs-input
                                            class="w-full"
                                            v-model="post_code"
                                            label-placeholder="Post Code"
                                            :danger="errors.post_code !== null"
                                            :danger-text="errors.post_code"
                                        ></vs-input>
                                    </div>
                                    <div class="vx-col w-1/2">
                                        <vs-select
                                            label="Country"
                                            v-model="country"
                                            :danger="errors.country !== null"
                                            :danger-text="errors.country"
                                        >
                                            <vs-select-item
                                                :key="index"
                                                :value="item.value"
                                                :text="item.text"
                                                v-for="(item,
                                                index) in countryOptions"
                                            />
                                        </vs-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vx-col w-full mb-6 mt-6">
                            <div>
                                <h4 class="text-primary mb-2">
                                    2. Contact Details
                                </h4>
                                <small
                                    >These are the contact details of the person
                                    within your company:</small
                                >
                            </div>
                        </div>

                        <div class="vx-col pt-4 w-full">
                            <div class="vx-row mb-8">
                                <div class="vx-col w-full sm:w-2/3 mb-6">
                                    <vs-input
                                        icon="icon-user"
                                        icon-pack="feather"
                                        class="w-full"
                                        v-model="contact_name"
                                        label="Contact Name"
                                        :danger="errors.contact_name !== null"
                                        :danger-text="errors.contact_name"
                                    ></vs-input>
                                </div>
                                <div class="vx-col w-full sm:w-2/3 mb-6">
                                    <vs-input
                                        icon="icon-mail"
                                        icon-pack="feather"
                                        class="w-full"
                                        v-model="email"
                                        label="Company Email"
                                        :danger="errors.email !== null"
                                        :danger-text="errors.email"
                                    ></vs-input>
                                </div>

                                <div class="vx-col w-full sm:w-2/3 mb-6">
                                    <vs-input
                                        icon="icon-phone"
                                        icon-pack="feather"
                                        class="w-full"
                                        v-model="contact_number"
                                        label="Contact Number"
                                        :danger="errors.contact_number !== null"
                                        :danger-text="errors.contact_number"
                                    ></vs-input>
                                </div>
                            </div>
                        </div>

                        <div class="vx-col w-full mb-6">
                            <div class="">
                                <h4 class="text-primary mb-2">
                                    3. Add Your Company's Logo
                                </h4>
                                <small
                                    >Please provide a hi-resolution file, this
                                    will be used in client commuincation:</small
                                >
                            </div>
                        </div>
                        <div class="vx-col pt-4 w-full">
                            <BtnImageSelect
                                :colour="errors.logo ? 'warning' : 'primary'"
                                :logo="logo"
                                @logoChanged="setLogo"
                                :acceptFiles="'image/*'"
                                :hidePreview="true"
                                type="border"
                                buttonTextAdd="Add Logo"
                                buttonTextChange="Change Logo"
                            ></BtnImageSelect>

                            <div
                                class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
                                style="height: 19px"
                            >
                                <span class="span-text-validation">
                                    {{ errors.logo }}
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div v-else-if="signUpFormComplete && broker" class="p-10">
                <div class="flex mb-6">
                    <h4 class="mr-3 text-primary">Company Section Complete</h4>

                    <feather-icon
                        svgClasses="h-6 w-6"
                        :icon="'CheckIcon'"
                        class="text-success mr-3"
                    ></feather-icon>
                </div>
                <h6 class="w-full mb-3">
                    {{ broker.company_name }}
                </h6>
                <p class="w-full">
                    {{ broker.address_line_1 }}
                </p>
                <p class="w-full">
                    {{ broker.address_line_2 }}
                </p>
                <p class="w-full">
                    {{ broker.post_town }}
                </p>
                <p class="w-full mb-3">
                    {{ broker.post_code }}
                </p>
                <p class="w-full">
                    {{ broker.contact_name }}
                </p>
                <p class="w-full">
                    {{ broker.email }}
                </p>
                <p class="w-full">
                    {{ broker.contact_number }}
                </p>
            </div>
        </div>
        <div
            v-if="!signUpFormComplete && company_name"
            class="vx-col hidden sm:block w-full sm:w-1/3 pt-4"
        >
            <OverviewCard
                :signUpFormComplete="signUpFormComplete"
                @gotoTab="$emit('gotoTab', $event)"
            />
        </div>
    </div>
</template>

<script>
import AddressSearch from '@/components/common/AddressSearch'
import BtnImageSelect from '@/components/common/BtnImageSelect'
import OverviewCard from '../tabs/Overview.vue'

export default {
    name: 'CompanyTab',
    components: {
        AddressSearch,
        BtnImageSelect,
        OverviewCard,
    },
    props: ['signUpFormComplete'],
    mounted() {
        this.errors = Object.assign({}, this.$store.getters.signUpFormErrors)
    },
    watch: {
        '$store.state.signUpFormErrors': {
            immediate: true,
            deep: true,
            handler(data) {
                this.errors = this.$store.getters.signUpFormErrors
                if (
                    this.errors.address_line_1 ||
                    this.errors.address_line_2 ||
                    this.errors.post_town ||
                    this.errors.post_code ||
                    this.errors.country
                )
                    this.show.editAddress = true
                else this.show.editAddress = false
            },
        },
    },
    computed: {
        broker() {
            return this.$store.getters.broker
        },
        company_name: {
            set(value) {
                this.$store.commit('signUpForm', {
                    company_name: value,
                })
            },
            get() {
                return this.$store.getters.signUpForm.company_name
            },
        },
        address_line_1: {
            set(value) {
                this.$store.commit('signUpForm', {
                    address_line_1: value,
                })
            },
            get() {
                return this.$store.getters.signUpForm.address_line_1
            },
        },
        address_line_2: {
            set(value) {
                this.$store.commit('signUpForm', {
                    address_line_2: value,
                })
            },
            get() {
                return this.$store.getters.signUpForm.address_line_2
            },
        },
        post_town: {
            set(value) {
                this.$store.commit('signUpForm', {
                    post_town: value,
                })
            },
            get() {
                return this.$store.getters.signUpForm.post_town
            },
        },
        post_code: {
            set(value) {
                this.$store.commit('signUpForm', {
                    post_code: value,
                })
            },
            get() {
                return this.$store.getters.signUpForm.post_code
            },
        },
        country: {
            set(value) {
                this.$store.commit('signUpForm', {
                    country: value,
                })
            },
            get() {
                return this.$store.getters.signUpForm.country
            },
        },
        email: {
            set(value) {
                this.$store.commit('signUpForm', {
                    email: value,
                })
            },
            get() {
                return this.$store.getters.signUpForm.email
            },
        },
        contact_number: {
            set(value) {
                this.$store.commit('signUpForm', {
                    contact_number: value,
                })
            },
            get() {
                return this.$store.getters.signUpForm.contact_number
            },
        },
        contact_name: {
            set(value) {
                this.$store.commit('signUpForm', {
                    contact_name: value,
                })
            },
            get() {
                return this.$store.getters.signUpForm.contact_name
            },
        },
        logo: {
            set(value) {
                this.$store.commit('signUpForm', {
                    logo: value,
                })
            },
            get() {
                return this.$store.getters.signUpForm.logo
            },
        },
    },
    data() {
        return {
            errors: null,
            show: {
                editAddress: false,
            },
            countryOptions: [
                {
                    value: null,
                    text: 'Select Country',
                },
                {
                    value: 'England',
                    text: 'England',
                },
                {
                    value: 'Scotland',
                    text: 'Scotland',
                },
                {
                    value: 'Wales',
                    text: 'Wales',
                },
                {
                    value: 'Northern Ireland',
                    text: 'Northern Ireland',
                },
            ],
        }
    },
    methods: {
        setAddress(value) {
            if (value) {
                this.address_line_1 = value.line_1
                this.address_line_2 = value.line_2
                this.post_town = value.post_town
                this.post_code = value.postcode
                this.country = value.country
            }
        },
        setLogo(event) {
            if (event) {
                this.logo = event
                this.$store.commit('signUpForm', {
                    logo: this.logo,
                })
            }
        },
    },
}
</script>

<style scoped>
input[type='file'] {
    display: none;
}
.file-size {
    font-size: 0.7em;
}
.alert-grey {
    padding: 20px;
    border-radius: 15px;
    background-color: rgba(var(--vs-primary), 0.6);
}
</style>

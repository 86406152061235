<template>
    <vx-card class="">
        <div
            slot="no-body"
            :style="{
                background: `rgba(var(--vs-primary),.7)`,
            }"
        >
            <div class="vx-row no-gutter justify-center p-6">
                <div class="text-center flex items-center">
                    <vs-avatar
                        v-if="signUpForm.logo"
                        :src="getImage"
                        size="large"
                    />
                    <h3 class="text-cente font-bold text-white">
                        {{ signUpForm.company_name || 'Your Company' }}
                    </h3>
                </div>
            </div>

            <div class="vx-row no-gutter w-full">
                <div class="vx-col w-full d-theme-dark-bg p-12">
                    <div v-if="!signUpFormComplete" class="vx-row mb-4">
                        <div class="vx-col w-3/4">
                            <p
                                v-if="signUpForm.address_line_1"
                                class="w-full text-sm"
                            >
                                {{ signUpForm.address_line_1 }}
                            </p>
                            <p
                                v-if="signUpForm.address_line_2"
                                class="w-full text-sm"
                            >
                                {{ signUpForm.address_line_2 }}
                            </p>
                            <p
                                v-if="signUpForm.post_town"
                                class="w-full text-sm"
                            >
                                {{ signUpForm.post_town }}
                            </p>
                            <p
                                v-if="signUpForm.post_code"
                                class="w-full text-sm"
                            >
                                {{ signUpForm.post_code }}
                            </p>
                            <p
                                v-if="signUpForm.email"
                                class="w-full mt-3 text-sm"
                            >
                                {{ signUpForm.email }}
                            </p>
                            <p
                                v-if="signUpForm.contact_name"
                                class="w-full text-sm"
                            >
                                {{ signUpForm.contact_name }}
                            </p>

                            <p
                                v-if="signUpForm.contact_number"
                                class="w-full text-sm"
                            >
                                {{ signUpForm.contact_number }}
                            </p>
                        </div>
                        <div class="vx-col w-1/4">
                            <div class="flex justify-end">
                                <feather-icon
                                    v-if="
                                        !signUpFormComplete &&
                                        signUpForm.address_line_1
                                    "
                                    svgClasses="h-3 w-3"
                                    @click="$emit('gotoTab', 0)"
                                    :icon="'EditIcon'"
                                    class="cursor-pointer text-primary"
                                ></feather-icon>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="
                            !signUpFormComplete &&
                            (signUpForm.text_from || signUpForm.code_prefix)
                        "
                        class="vx-row mb-3 text-sm"
                    >
                        <div class="vx-col w-3/4">
                            <p
                                v-if="signUpForm.text_from"
                                class="w-full text-sm"
                            >
                                SMS From: {{ signUpForm.text_from }}
                            </p>
                            <p
                                v-if="signUpForm.code_prefix"
                                class="w-full text-sm"
                            >
                                Code Prefix: {{ signUpForm.code_prefix }}
                            </p>
                        </div>

                        <div class="vx-col w-1/4">
                            <div class="flex justify-end">
                                <feather-icon
                                    svgClasses="h-3 w-3"
                                    @click="$emit('gotoTab', 1)"
                                    :icon="'EditIcon'"
                                    class="cursor-pointer"
                                ></feather-icon>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="!signUpFormComplete && plan"
                        class="vx-row mb-4 text-sm"
                    >
                        <div class="vx-col w-3/4">
                            <p v-if="plan" class="w-full">
                                Plan: {{ plan.plan }} £{{
                                    plan.monthly_charge
                                }}pm
                            </p>
                        </div>
                        <div class="vx-col w-1/4">
                            <div class="flex justify-end">
                                <feather-icon
                                    v-if="!signUpFormComplete"
                                    svgClasses="h-3 w-3"
                                    @click="$emit('gotoTab', 2)"
                                    :icon="'EditIcon'"
                                    class="cursor-pointer"
                                ></feather-icon>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="
                            !signUpFormComplete &&
                            signUpForm.access_emails &&
                            signUpForm.access_emails.length
                        "
                    >
                        <h6 class="mb-3">Additional Users</h6>
                        <div
                            v-for="(email, index) in signUpForm.access_emails"
                            :key="index"
                            class="vx-row access-email text-xs"
                        >
                            <div class="vx-col w-3/4">
                                {{ email }}
                            </div>
                            <div class="vx-col w-1/4 flex flex-row-reverse">
                                <feather-icon
                                    svgClasses="h-3 w-3"
                                    @click="removeAccessEmail"
                                    :icon="'TrashIcon'"
                                    class="cursor-pointer hidden-element text-warning"
                                ></feather-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
export default {
    name: 'OverviewCard',
    props: ['signUpFormComplete'],

    computed: {
        broker() {
            return this.$store.getters.broker
        },
        signUpForm() {
            return this.$store.getters.signUpForm
        },
        plan() {
            return this.$store.getters.selectedPlanDetails
        },
        getImage() {
            if (this.signUpForm.logo && this.signUpForm.logo instanceof File) {
                var url = URL.createObjectURL(this.signUpForm.logo)
                return url
            }
            return this.signUpForm.logo
        },
    },
    methods: {
        removeAccessEmail(index) {
            this.$store.commit('removeAccessEmail', index)
        },
    },
}
</script>

<style scoped>
.image {
    border-radius: 10px;
    max-width: 200px;
    height: auto;
}
.hidden-elements {
    visibility: hidden;
}
.access-email:hover .hidden-elements {
    visibility: visible;
}
</style>

<template>
    <div class="vx-row mt-6 mb-6">
        <div class="vx-col w-full sm:w-2/3 mb-base">
            <div
                class="vs-con-loading__container show-overflow items-center"
                id="step5"
            >
                <form
                    v-if="
                        signUpFormComplete &&
                        !billingCustomerSetupComplete &&
                        !billingMandateSetupComplete
                    "
                >
                    <div class="vx-row">
                        <div class="vx-col p-10 pt-4 w-full">
                            <div class="vx-col w-full">
                                <div class="mb-6">
                                    <h4 class="text-primary mb-2">
                                        10. Billing Details
                                    </h4>
                                    <small>Billing company and address:</small>
                                </div>
                            </div>

                            <div class="vx-row mt-10">
                                <div class="vx-col w-full sm:w-2/3 mb-6">
                                    <vs-input
                                        class="w-full"
                                        v-model="billingCompanyName"
                                        label-placeholder="Company Name"
                                        :danger="
                                            errors.billingCompanyName !== null
                                        "
                                        :danger-text="errors.billingCompanyName"
                                    ></vs-input>
                                </div>
                                <div class="vx-col w-full sm:w-2/3 mb-6">
                                    <vs-input
                                        class="w-full"
                                        v-model="billingEmail"
                                        label-placeholder="Email"
                                        :danger="errors.billingEmail !== null"
                                        :danger-text="errors.billingEmail"
                                    ></vs-input>
                                </div>
                                <div class="vx-col w-full sm:w-2/3 mb-6">
                                    <vs-input
                                        class="w-full"
                                        v-model="billingAddressLine1"
                                        label-placeholder="Billing Address 1"
                                        :danger="
                                            errors.billingAddressLine1 !== null
                                        "
                                        :danger-text="
                                            errors.billingAddressLine1
                                        "
                                    ></vs-input>
                                </div>
                            </div>
                            <div class="vx-row mb-8">
                                <div class="vx-col w-full sm:w-1/3 mb-6">
                                    <vs-input
                                        class="w-full"
                                        v-model="billingTownCity"
                                        label-placeholder="Town/City"
                                        :danger="
                                            errors.billingTownCity !== null
                                        "
                                        :danger-text="errors.billingTownCity"
                                    ></vs-input>
                                </div>
                                <div class="vx-col w-full sm:w-1/3 mb-6">
                                    <vs-input
                                        class="w-full"
                                        v-model="billingPostCode"
                                        label-placeholder="Post Code"
                                        :danger="
                                            errors.billingPostCode !== null
                                        "
                                        :danger-text="errors.billingPostCode"
                                    ></vs-input>
                                </div>
                            </div>
                            <div class="vx-col w-full">
                                <div class="mb-6">
                                    <h4 class="text-primary mb-2">
                                        11. Bank Details
                                    </h4>
                                    <small>Account number and sort code:</small>
                                </div>
                            </div>

                            <div class="vx-row mb-8 mt-10">
                                <div class="vx-col w-full sm:w-1/2 mb-6">
                                    <vs-input
                                        class="w-full"
                                        v-model="billingSortCode"
                                        label-placeholder="Sort Code"
                                        :danger="
                                            errors.billingSortCode !== null
                                        "
                                        :danger-text="errors.billingSortCode"
                                    ></vs-input>
                                </div>
                                <div class="vx-col w-full sm:w-1/2 mb-6">
                                    <vs-input
                                        class="w-full"
                                        v-model="billingAccountNumber"
                                        label-placeholder="Account Number"
                                        :danger="
                                            errors.billingAccountNumber !== null
                                        "
                                        :danger-text="
                                            errors.billingAccountNumber
                                        "
                                    ></vs-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div
                    v-else-if="
                        signUpFormComplete &&
                        billingCustomerSetupComplete &&
                        !billingMandateSetupComplete
                    "
                >
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <div class="mb-6">
                                <h4 class="text-primary mb-2">
                                    Confirm your Bank Details
                                </h4>
                                <small
                                    >Check your bank details are correct before
                                    proceeding:</small
                                >
                            </div>
                        </div>

                        <div class="vx-col w-full mb-12">
                            <div class="mb-6">
                                <p class="mb-2">
                                    <span class="font-bold"
                                        >Account Holder:</span
                                    >
                                    {{ bankAccount.account_holder_name }}
                                </p>
                                <p class="mb-2">
                                    <span class="font-bold">Sort Code:</span>
                                    {{ billingSortCode }}
                                </p>
                                <p class="mb-2">
                                    <span class="font-bold"
                                        >Account Number:</span
                                    >
                                    {{ billingAccountNumber }}
                                </p>
                                <p class="mb-2">
                                    <span class="font-bold">Bank Name:</span>
                                    {{ bankAccount.bank_name }}
                                </p>
                            </div>
                            <vs-button
                                type="border"
                                size="small"
                                @click="
                                    $store.commit(
                                        'brokerRemoveGoCardlessCustomer'
                                    )
                                "
                                >Change</vs-button
                            >
                        </div>

                        <div class="vx-col w-full">
                            <p class="mb-3">
                                Payments are processed by Gocardless
                            </p>
                            <img
                                alt="Direct Debit payments by GoCardless"
                                class="go-cardless mb30"
                                src="https://mylastwill.s3.amazonaws.com/static/img/gocardless-small.png"
                                width="211"
                            />
                            <br />
                            <a
                                class="link"
                                href="https://gocardless.com/direct-debit/guarantee/"
                                target="_blank"
                                >The Direct Debit Guarantee</a
                            >
                        </div>
                    </div>
                </div>
                <div
                    v-else-if="
                        signUpFormComplete &&
                        billingCustomerSetupComplete &&
                        billingMandateSetupComplete &&
                        mandate &&
                        bankAccount
                    "
                    class="pr-12"
                >
                    <div class="vx-col w-full">
                        <div class="mb-6">
                            <h4 class="text-primary mb-2">
                                MyLastWill Ltd will charge you £{{
                                    plan.monthly_charge
                                }}
                                per month
                            </h4>
                        </div>
                    </div>

                    <p class="mb-6">
                        We will debit your
                        <span class="font-bold">{{
                            bankAccount.bank_name
                        }}</span>
                        ending
                        <span class="font-bold"
                            >*****{{ bankAccount.account_number_ending }}</span
                        >
                        by Direct Debit on or shortly after
                        <span class="font-bold">The 1st of every month</span>.
                    </p>

                    <p class="mb-6">
                        This payment and will appear on your bank statement as
                        <span class="font-bold">mylastwill.co.uk</span> with the
                        reference
                        <span class="font-bold">{{ mandate.reference }}</span
                        >. We will notify you by email 3 days in advance of each
                        payment.
                    </p>
                    <p class="mb-6">
                        If any of the above details are incorrect, please call
                        us on
                        <span class="font-bold">01785 338292</span> or email us
                        at
                        <span class="font-bold">payments@mylastwill.co.uk</span>
                    </p>
                </div>
                <div v-else>
                    <p>Details not found</p>
                </div>
            </div>
        </div>
        <div class="vx-col hidden sm:block w-full sm:w-1/3">
            <OverviewBillingCard :signUpFormComplete="signUpFormComplete" />
        </div>
    </div>
</template>

<script>
import { http } from '@/services'
import OverviewBillingCard from '../tabs/OverviewBilling.vue'

export default {
    name: 'BillingTab',
    components: {
        OverviewBillingCard,
    },
    props: [
        'signUpFormComplete',
        'billingCustomerSetupComplete',
        'billingMandateSetupComplete',
    ],
    watch: {
        broker: {
            immediate: true,
            deep: true,
            handler(value) {
                // pre-populates billing form
                if (
                    value &&
                    !this.billingCompanyName &&
                    !this.billingEmail &&
                    !this.billingAddressLine1 &&
                    !this.billingTownCity &&
                    !this.billingTownCity
                ) {
                    this.billingCompanyName = this.broker.company_name
                    this.billingAddressLine1 = this.broker.address_line_1
                    this.billingTownCity = this.broker.post_town
                    this.billingPostCode = this.broker.post_code
                    this.billingEmail = this.broker.email
                }
            },
        },
        '$store.state.signUpBillingErrors': {
            immediate: true,
            deep: true,
            handler(data) {
                this.errors = this.$store.getters.signUpBillingErrors
            },
        },
    },
    computed: {
        broker() {
            return this.$store.getters.broker
        },
        mandate() {
            return this.$store.getters.mandate
        },
        bankAccount() {
            return this.$store.getters.bankAccount
        },
        plan() {
            return this.$store.getters.selectedPlanDetails
        },
        billingEmail: {
            set(value) {
                this.$store.commit('signUpBillingForm', {
                    billingEmail: value,
                })
            },
            get() {
                return this.$store.getters.signUpBillingForm.billingEmail
            },
        },
        billingCompanyName: {
            set(value) {
                this.$store.commit('signUpBillingForm', {
                    billingCompanyName: value,
                })
            },
            get() {
                return this.$store.getters.signUpBillingForm.billingCompanyName
            },
        },
        billingAddressLine1: {
            set(value) {
                this.$store.commit('signUpBillingForm', {
                    billingAddressLine1: value,
                })
            },
            get() {
                return this.$store.getters.signUpBillingForm.billingAddressLine1
            },
        },
        billingTownCity: {
            set(value) {
                this.$store.commit('signUpBillingForm', {
                    billingTownCity: value,
                })
            },
            get() {
                return this.$store.getters.signUpBillingForm.billingTownCity
            },
        },
        billingPostCode: {
            set(value) {
                this.$store.commit('signUpBillingForm', {
                    billingPostCode: value,
                })
            },
            get() {
                return this.$store.getters.signUpBillingForm.billingPostCode
            },
        },
        billingCountry: {
            set(value) {
                this.$store.commit('signUpBillingForm', {
                    billingCountry: value,
                })
            },
            get() {
                return this.$store.getters.signUpBillingForm.billingCountry
            },
        },
        billingSortCode: {
            set(value) {
                this.$store.commit('signUpBillingForm', {
                    billingSortCode: value,
                })
            },
            get() {
                return this.$store.getters.signUpBillingForm.billingSortCode
            },
        },
        billingAccountNumber: {
            set(value) {
                this.$store.commit('signUpBillingForm', {
                    billingAccountNumber: value,
                })
            },
            get() {
                return this.$store.getters.signUpBillingForm
                    .billingAccountNumber
            },
        },
    },
    data() {
        return {
            errors: null,
            loading: false,
        }
    },
    methods: {
        createCustomer() {
            if (this.validatePage) {
                this.loading = true
                http.post(
                    '/wills/api/direct_debit_setup_customer_bank',
                    this.form
                )
                    .then((response) => {
                        this.$store.commit('directDebitForm', this.form)
                        this.$store.commit('bankAccountSetup', response.data)
                        this.$router
                            .push('/pay/direct_debit_confirmation')
                            .catch((error) => {
                                console.log(error)
                            })
                        this.loading = false
                    })
                    .catch((error) => {
                        this.loading = false
                        this.handleErrors(error.response.data)
                    })
            }
        },
        handleErrors(error) {
            this.clearErrors()
            if (error.errors) {
                var errors = error.errors
                if (errors && Array.isArray(errors)) {
                    for (var i = 0; i < errors.length; i++) {
                        if (errors[i].field === 'account_number')
                            this.error.accountNumber = `Account Number ${errors[i].message}`
                        if (errors[i].field === 'branch_code')
                            this.error.sortCode = `Sort Code ${errors[i].message}`
                        if (errors[i].field === 'postal_code')
                            this.error.postCode = `Post Code ${errors[i].message}`
                        if (errors[i].field === 'email')
                            this.error.email = `Email ${errors[i].message}`
                    }
                } else {
                    this.$notification.error(errors)
                }
            } else {
                this.$notification.error(error)
            }
        },
        clearErrors() {
            this.error.accountNumber = null
            this.error.firstName = null
            this.error.lastName = null
            this.error.email = null
            this.error.sortCode = null
            this.error.accountNumber = null
            this.error.billingAddressLine1 = null
            this.error.townCity = null
            this.error.postCode = null
        },
    },
}
</script>

<template>
    <div>
        <label class="vs-input--label">Address Search</label>
        <v-select
            v-model="selected"
            @search="fetchAddressList"
            @input="getSelected"
            :options="options"
            :class="colour"
        >
            <template #search="{ attributes, events }">
                <i
                    class="vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon-map null"
                ></i>
                <input
                    class="vs__search pl-12"
                    v-bind="attributes"
                    v-on="events"
                />
            </template>
        </v-select>
    </div>
</template>

<script>
import { http } from '@/services'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
    name: 'AddressSearch',
    props: ['colour'],
    components: {
        vSelect,
    },
    data() {
        return {
            postCodeSearch: null,
            selected: null,
            options: [],
            input: null,
            apiKey: 'ak_jgmk4levJtTl4GYQLZcfzG9wKyi3m',
        }
    },
    watch: {
        postCodeSearch(value) {
            if (value) this.fetchAddressList(value)
        },
    },
    methods: {
        fetchAddressList(query) {
            if (query.length >= 3) {
                var address = `https://api.ideal-postcodes.co.uk/v1/autocomplete/addresses?api_key=${this.apiKey}&query=${query}`
                http.get(address)
                    .then((response) => {
                        this.options = response.data.result.hits.map(
                            (value) => {
                                return {
                                    label:
                                        value.suggestion.slice(
                                            0,
                                            value.suggestion.length - 4
                                        ) + query,
                                    value: value,
                                }
                            }
                        )
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
        getSelected(value) {
            if (value) {
                var address = `https://api.ideal-postcodes.co.uk/${value.value.urls.udprn}?api_key=${this.apiKey}`
                http.get(address)
                    .then((response) => {
                        if (response.data.message === 'Success') {
                            this.$emit('addressSelected', response.data.result)
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
    },
}
</script>

<style lang="scss">
.vs--single .vs__selected {
    background-color: rgba(var(--vs-success), 0.7);
    padding: 5px;
    color: white;
    margin-left: 40px;
}
.grey .vs__dropdown-toggle {
    background: #f9f9f9;
    /*border-bottom-color: #e56781;*/
    border-bottom-width: 3px;
}
.v-select .vs__dropdown-toggle {
    padding: 1px 0px 10px 0px;
}
.v-select {
    background: white;
}
</style>

<template>
    <div class="h-screen container" id="page-login">
        <div class="vx-row flex justify-center">
            <div
                class="vx-col lg:w-3/4 xl:w-4/5 m-0 mb-0 pb-0 p-4 w-full pt-4 flex justify-between"
            >
                <div>
                    <h1>Broker <strong>Registration</strong></h1>
                </div>
                <div class="hidden sm:block">
                    <picture>
                        <source
                            type="image/webp"
                            srcset="@/assets/images/logo/logo.webp"
                        />
                        <source
                            type="image/png"
                            srcset="@/assets/images/logo/logo.png"
                        />
                        <img
                            src="@/assets/images/logo/logo.png"
                            alt="login"
                            class="mx-auto"
                            style="width: 80%; max-width: 200px"
                        />
                    </picture>
                </div>
            </div>
        </div>
        <div class="vx-row flex justify-center">
            <div class="vx-col w-full lg:w-3/4 xl:w-4/5 m-0 sm:m-4 mt-0">
                <form-wizard
                    color="rgba(var(--vs-primary), 1)"
                    errorColor="rgba(var(--vs-warning), 1)"
                    :title="null"
                    :subtitle="null"
                    :nextButtonText="nextButtonText"
                    finishButtonText="Login"
                    step-size="xs"
                    ref="wizard"
                >
                    <tab-content
                        :title="smallScreen ? '' : 'Company'"
                        icon="feather icon-home"
                        :before-change="validateStep1"
                        class="mb-5"
                    >
                        <CompanyTab
                            :signUpFormComplete="signUpFormComplete"
                            @gotoTab="gotoTab"
                        />
                    </tab-content>
                    <tab-content
                        :title="smallScreen ? '' : 'Vouchers'"
                        icon="feather icon-at-sign"
                        :before-change="validateStep2"
                        class="mb-5"
                    >
                        <InvitationsTab
                            :signUpFormComplete="signUpFormComplete"
                            @gotoTab="gotoTab"
                        />
                    </tab-content>
                    <tab-content
                        :title="smallScreen ? '' : 'Plan'"
                        icon="feather icon-book"
                        :before-change="validateStep3"
                        class="mb-5"
                    >
                        <PlanTab
                            :signUpFormComplete="signUpFormComplete"
                            @gotoTab="gotoTab"
                        />
                    </tab-content>

                    <tab-content
                        :title="smallScreen ? '' : 'Users'"
                        icon="feather icon-users"
                        :before-change="validateStep4"
                        class="mb-5"
                    >
                        <AccessTab
                            :signUpFormComplete="signUpFormComplete"
                            @gotoTab="gotoTab"
                        />
                    </tab-content>
                    <tab-content
                        :title="smallScreen ? '' : 'Billing'"
                        icon="feather icon-credit-card"
                        :before-change="validateStep5"
                        class="mb-5"
                    >
                        <BillingTab
                            :signUpFormComplete="signUpFormComplete"
                            :billingCustomerSetupComplete="
                                billingCustomerSetupComplete
                            "
                            :billingMandateSetupComplete="
                                billingMandateSetupComplete
                            "
                        />
                    </tab-content>
                    <tab-content
                        :title="smallScreen ? '' : 'Confirmation'"
                        icon="feather icon-award"
                        :before-change="validateStep6"
                        class="mb-5"
                    >
                        <ConfirmationTab
                            :signUpFormComplete="signUpFormComplete"
                            @login="validateStep6"
                        />
                    </tab-content>
                </form-wizard>
            </div>
        </div>
    </div>
</template>

<script>
import { http } from '@/services'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import CompanyTab from '@/views/signup/tabs/Company'
import InvitationsTab from '@/views/signup/tabs/Invitations'

import PlanTab from '@/views/signup/tabs/Plans'
import AccessTab from '@/views/signup/tabs/Access'
import ConfirmationTab from '@/views/signup/tabs/Confirmation'
import BillingTab from '@/views/signup/tabs/Billing'

export default {
    name: 'SignUp',
    components: {
        BillingTab,
        InvitationsTab,
        CompanyTab,
        FormWizard,
        TabContent,
        PlanTab,
        AccessTab,
        FormWizard,
        TabContent,
        ConfirmationTab,
    },
    mounted() {
        if (this.tokens && this.tokens.access && this.tokens.refresh) {
            // reset access tokens
            let data = {
                access: this.tokens.access,
                refresh: this.tokens.refresh,
                noRedirect: true,
            }
            this.$store.dispatch('inspectToken', true)
            // this.$store.dispatch('_setTokens', data)
        }
        if (this.signUpFormComplete) {
            this.$store.commit('signUpFormClear')
            this.$refs.wizard.changeTab(0, 4)
        }
    },
    data() {
        return {
            show: {
                direct_debit_confirmation: false,
            },
        }
    },
    computed: {
        smallScreen() {
            return this.$store.getters.windowBreakPoint === 'xs'
        },
        signUpForm() {
            return this.$store.getters.signUpForm
        },
        broker() {
            return this.$store.getters.broker
        },
        tokens() {
            return this.$store.getters.tokens
        },
        signUpFormComplete() {
            return !!(
                this.broker &&
                this.broker.email &&
                this.broker.account_number
            )
        },
        billingCustomerSetupComplete() {
            return !!(
                this.signUpFormComplete && this.broker.go_card_less_customer_id
            )
        },
        billingMandateSetupComplete() {
            return !!(
                this.billingCustomerSetupComplete &&
                this.broker.go_card_less_mandate_id
            )
        },
        nextButtonText() {
            if (
                this.signUpFormComplete &&
                this.billingCustomerSetupComplete &&
                !this.billingMandateSetupComplete
            )
                return 'Confirm'
            return 'Next'
        },
    },
    methods: {
        validateStep1() {
            this.$scrollTo('#page-login')

            if (this.signUpFormComplete) return true
            // validate company form
            this.$store.commit('signUpFormErrorsClear')
            if (!this.signUpForm.logo) {
                this.$store.commit('signUpFormErrors', {
                    logo: ['Logo required'],
                })
                return false
            }

            this.startLoading('step1')
            let data = {
                company_name: this.signUpForm.company_name,
                email: this.signUpForm.email,
                address_line_1: this.signUpForm.address_line_1,
                address_line_2: this.signUpForm.address_line_2,
                post_town: this.signUpForm.post_town,
                post_code: this.signUpForm.post_code,
                country: this.signUpForm.country,
                contact_name: this.signUpForm.contact_name,
                contact_number: this.signUpForm.contact_number,
            }
            return http
                .post('sign_up_validate', data)
                .then((response) => {
                    this.stopLoading('step1')
                    return true
                })
                .catch((errors) => {
                    this.stopLoading('step1')
                    this.handleServerErrors(errors.response.data)
                    return false
                })
        },
        validateStep2() {
            this.$scrollTo('#page-login')

            if (this.signUpFormComplete) return true

            this.startLoading('step2')
            // validates invitations form
            this.$store.commit('signUpFormErrorsClear')
            let data = {
                code_prefix: this.signUpForm.code_prefix,
                text_from: this.signUpForm.text_from,
            }
            return http
                .post('sign_up_validate', data)
                .then((response) => {
                    this.stopLoading('step2')
                    return true
                })
                .catch((errors) => {
                    this.stopLoading('step2')
                    this.handleServerErrors(errors.response.data)
                    return false
                })
        },
        validateStep3() {
            this.$scrollTo('#page-login')

            if (this.signUpFormComplete) return true
            this.startLoading('step3')

            // validate plans form
            this.$store.commit('signUpFormErrorsClear')
            let data = {
                plan: this.signUpForm.plan,
            }
            return http
                .post('sign_up_validate', data)
                .then((response) => {
                    this.stopLoading('step3')
                    return true
                })
                .catch((errors) => {
                    this.stopLoading('step3')
                    this.handleServerErrors(errors.response.data)
                    return false
                })
        },
        validateStep4() {
            this.$scrollTo('#page-login')

            if (this.signUpFormComplete) return true
            this.startLoading('step4')

            this.$store.commit('signUpFormErrorsClear')
            let data = {
                username: this.signUpForm.username,
                password1: this.signUpForm.password1,
                password2: this.signUpForm.password2,
                terms_and_conditions: this.signUpForm.terms_and_conditions,
            }
            // validate username and password

            return http
                .post('sign_up_validate_user', data)
                .then((response) => {
                    return this.signUp()
                })
                .catch((error) => {
                    this.stopLoading('step4')
                    this.handleServerErrors(error.response.data)
                    return false
                })
            // creates broker and user account
        },
        validateStep5() {
            this.$scrollTo('#page-login')

            // sets up direct debit
            if (
                this.signUpFormComplete &&
                !this.billingCustomerSetupComplete &&
                !this.billingMandateSetupComplete
            ) {
                this.startLoading('step5')

                return this.setUpCustomerAndBank()
            } else if (
                this.signUpFormComplete &&
                this.billingCustomerSetupComplete &&
                !this.billingMandateSetupComplete
            ) {
                this.startLoading('step5')
                return this.setUpMandate()
            } else if (
                this.signUpFormComplete &&
                this.billingCustomerSetupComplete &&
                this.billingMandateSetupComplete &&
                !this.show.direct_debit_confirmation
            ) {
                this.show.direct_debit_confirmation = true
                return false
            }
            this.$store.commit('mandate', null)
            this.$store.commit('bankAccount', null)
            return true
        },
        validateStep6() {
            this.$scrollTo('#page-login')
            console.log('clear all forms')
            // clear all sign up forms
            this.$store.commit('mandate', null)
            this.$store.commit('bankAccount', null)
            this.$store.commit('signUpBillingFormClear')
            this.$store.commit('signUpFormClear')
            this.$store.commit('broker', null)
            this.$store.commit('selectedPlanDetails', null)
            this.$store.dispatch('logout')
            this.$store.commit('loginTabIndex', 1)
            // this.$router.push({ name: 'home' }).catch((e) => console.log(e))
        },
        handleServerErrors(errors) {
            this.$store.commit('signUpFormErrors', errors)
        },
        signUp() {
            let formData = new FormData()
            Object.keys(this.$store.getters.signUpForm).forEach(
                (key, index) => {
                    formData.append(key, this.$store.getters.signUpForm[key])
                }
            )
            return http
                .post('sign_up', formData)
                .then((response) => {
                    this.stopLoading('step4')

                    let data = {
                        access: response.data.tokens.access,
                        refresh: response.data.tokens.refresh,
                        noRedirect: true,
                    }
                    this.$store.dispatch('_setTokens', data)
                    this.$store.commit('broker', response.data.broker)
                    this.$store.commit('signUpFormClear')
                    return true
                })
                .catch((error) => {
                    this.handleServerErrors(error.response.data)
                    this.stopLoading('step4')

                    // goto error
                    let errors = error.response.data

                    if (
                        errors.email ||
                        errors.address_line_1 ||
                        errors.address_line_2 ||
                        errors.post_town ||
                        errors.post_code ||
                        errors.logo ||
                        errors.contact_name ||
                        errors.contact_number ||
                        errors.company_name
                    ) {
                        this.$refs.wizard.changeTab(0, 0)
                    } else if (
                        errors.code_prefix ||
                        errors.text_from ||
                        errors.auto_send
                    ) {
                        this.$refs.wizard.changeTab(0, 1)
                    } else if (errors.plan) {
                        this.$refs.wizard.changeTab(0, 2)
                    } else if (
                        errors.username ||
                        errors.password2 ||
                        errors.password1 ||
                        errors.terms_and_conditions
                    ) {
                        this.$refs.wizard.changeTab(0, 3)
                    }
                    return false
                })
        },
        setUpCustomerAndBank() {
            this.$store.commit('signUpBillingErrorsClear')
            return http
                .post(
                    'dd_customer_setup',
                    this.$store.getters.signUpBillingForm
                )
                .then((response) => {
                    this.nextButtonText = 'Confirm'

                    this.stopLoading('step5')

                    this.$store.commit('bankAccount', response.data.bank)
                    this.$store.commit('broker', response.data.broker)
                    return false
                })
                .catch((error) => {
                    this.stopLoading('step5')

                    this.$store.commit(
                        'signUpBillingErrors',
                        error.response.data
                    )
                    this.$vs.notify({
                        title: 'Error',
                        text: 'Form Errors',
                        color: 'warning',
                        position: 'top-center',
                    })
                    return false
                })
        },
        setUpMandate() {
            return http
                .post('dd_mandate_setup')
                .then((response) => {
                    this.stopLoading('step5')
                    this.$store.commit('mandate', response.data.mandate)
                    this.$store.commit('broker', response.data.broker)
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Direct Debit Setup',
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-shopping-bag',
                        position: 'top-center',
                    })
                    return false
                })
                .catch((error) => {
                    this.stopLoading('step5')
                    this.$vs.notify({
                        title: 'Error',
                        text: error,
                        color: 'warning',
                        position: 'top-center',
                    })
                    return false
                })
        },
        startLoading(id) {
            this.$vs.loading({
                color: 'primary',
                container: `#${id}`,
                scale: 0.45,
            })
        },
        stopLoading(id) {
            this.$vs.loading.close(`#${id} > .con-vs-loading`)
        },
        scrollToTop() {
            console.log('scroll to top')
            window.scrollTo(0, 0)
        },
        gotoTab(tab) {
            this.$refs.wizard.changeTab(0, tab)
        },
    },
}
</script>

<style lang="scss">
.empty-box {
    min-height: 400px;
    width: 100%;
    border: 5px dashed lightgray;
    border-radius: 10px;
}
.alert {
    padding: 10px 15px 10px 15px;
    background-color: rgba(var(--vs-primary), 0.7);
    color: white;
    border-radius: 8px;
}
.wizard-header {
    padding: 0 !important;
}

.wizard-btn {
    background-color: black !important;
    border-color: black !important;
}
</style>

<template>
    <vx-card v-if="plan">
        <div
            slot="no-body"
            :style="{
                background: `rgba(var(--vs-primary),.7)`,
            }"
        >
            <div class="vx-row no-gutter justify-center p-12">
                <div class="text-center flex items-center">
                    <h3 class="text-cente font-bold text-white">
                        About Your Bill
                    </h3>
                </div>
            </div>
            <div class="vx-row no-gutter w-full">
                <div class="vx-col w-full d-theme-dark-bg p-12">
                    <div class="vx-row mb-4">
                        <div class="vx-col w-full mb-6">
                            <p class="text-sm">
                                Your monthly bill will be
                                <span class="font-bold"
                                    >£{{ plan.monthly_charge }}</span
                                >
                                minus any commissions from products sold to your
                                clients and monies brought in from charges for
                                Wills.
                            </p>
                            <p class="mt-3 text-sm">
                                Payments are protected by the Direct Debit
                                Guarantee.
                            </p>
                        </div>

                        <div>
                            <a
                                class="link"
                                href="https://gocardless.com/direct-debit/guarantee/"
                                target="_blank"
                            >
                                <img
                                    src="https://mylastwill.s3.amazonaws.com/static/img/direct-debit-logo.jpg"
                                    alt="Direct Debit logo"
                                    width="170"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
export default {
    name: 'OverviewBillingCard',
    props: ['signUpFormComplete'],

    computed: {
        broker() {
            return this.$store.getters.broker
        },
        signUpForm() {
            return this.$store.getters.signUpForm
        },
        plan() {
            return this.$store.getters.selectedPlanDetails
        },
    },
    methods: {},
}
</script>

<style scoped>
.image {
    border-radius: 10px;
    max-width: 200px;
    height: auto;
}
.hidden-elements {
    visibility: hidden;
}
.access-email:hover .hidden-elements {
    visibility: visible;
}
</style>
